// In jQuery to avoid JS bugs on mobile with click simulation.
// This function is conditioned based on the value of #is_loading in the DOM to avoid too many clicks during the request. See => generateNewBlocProd.

// The isInViewport function is used to check if the button is visible on the screen. If it is, the click is simulated; otherwise, it is not. This function is called in the click condition during scrolling.

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

$(function () {
    var isclicked = false;
    var mainContainer = document.querySelector("body.category");

    if (mainContainer) {
        window.addEventListener('scroll', handleScroll);
    }

    function handleScroll() {
        var isLoading = $('#is_loading').val();
        var totalElem = document.getElementById('totalElems') ? document.getElementById('totalElems') : document.getElementById('nb_products');
        const maxItems = totalElem.value;
        const productLoaded = $('#list_item .item:not(".push")').length;
        const pagerNav = document.querySelector('.pagerNav.end_pagi');
        const button = document.querySelector('.see_all_product');

        if (isInViewport(button) && isLoading == 0) {
            $('.button.see_all_product').trigger('click');
            $('#is_loading').val('1');
        }
    }
});